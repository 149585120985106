<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              name="Anagrafica"
                              :label="beForm[rep].registry_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('registry_id')"
                              :readonly="true"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        label="Descrizione"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="unsolved_at"
                        :name="beForm[rep].unsolved_at.label"
                        label="Data"
                        v-model="form[rep].unsolved_at"
                        :rules="getRules('unsolved_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].amount.label"
                        vid="amount"
                        label="Ammontare"
                        v-model="form[rep].amount"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="getRules('amount')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import FormMixin from "@/mixins/FormMixin";
// import { mapActions } from "vuex";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";

export default {
  mixins: [FormMixin, ShortcutMixin, ConfirmModalMixin],
  data() {
    return {
      repository: "unsolved_payment",
      registry_data: null,
      form: {
        inpt_label: "",
        quick_value: "",
        unsolved_payment: {
          status_payment: 0,
          title: null,
          unsolved_at: null,
          amount: null,
          registry_id: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    QuickSearchRegistryModal,
    BaseDatepicker,
    BaseCurrency,
  },
  methods: {
    toInfoData,
    openQuickSearchRegistry() {
      this.form[this.rep].registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.form[this.rep].registry_id = value.id;
      this.registry_data = value;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onSubmit() {
      this.isLoading = true;
      this.store(this.repository)
        .then(() => {
          // this.id = response.data.data.id;
          this.$showSnackbar({
            preset: "success",
            text: `Insoluto creato con successo`,
          });
          this.shortcut(
            // "module.CONT.???",
            "book_entries.index",
            null,
            "#UnsolvedPayments",
            "filterUnsolvedPayments"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
